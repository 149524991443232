import { Link, Outlet, useLocation } from "react-router-dom";
import {
  Alert,
  Box,
  BudgeUIProvider,
  capitalize,
  InfoIcon,
  ModalManager,
  Stack,
  TColorToken,
  Text,
  Toaster,
} from "@budgeinc/budge-ui-core";
import getStoreConfig from "store/index";
import { Provider as ReduxProvider } from "react-redux";
import { SessionContextProvider } from "SessionContext";
import ErrorManager from "components/ErrorManager";
import { budgeEnv, isDemo, isDev, isProd } from "utils/env";

const RouterRoot = () => {
  const location = useLocation();

  return (
    <Box f={1}>
      {!isProd && (
        <Alert color={getTopBgColor()} contentColor="white" variant="filled" r={0} py="xs">
          <Stack.Horizontal alignItems="center" justifyContent="center">
            <Text color="white" fw="600" ta="center" variant="bodySmall">
              You&apos;re in {capitalize(budgeEnv.toLowerCase())} mode
            </Text>
            {location.pathname !== "/diag" && (
              <Link
                to={{
                  pathname: "/diag",
                  search: `next=${location.pathname}`,
                }}
                style={{
                  display: "flex",
                }}
              >
                <InfoIcon size={16} color="white" />
              </Link>
            )}
          </Stack.Horizontal>
        </Alert>
      )}
      <BudgeUIProvider>
        <SessionContextProvider>
          <Outlet />
          <ErrorManager />
          <ModalManager />
          <Toaster />
        </SessionContextProvider>
      </BudgeUIProvider>
    </Box>
  );
};

const getTopBgColor = (): TColorToken | undefined => {
  if (isDev) return "green";
  if (isDemo) return "yellow.5";

  return "red"; // Test
};

export default RouterRoot;
