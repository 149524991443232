import {
  AddUserIcon,
  AdvancedSettingsIcon,
  Box,
  DocumentIcon,
  DownloadIcon,
  InsightIcon,
  MessageIcon,
  NavBar,
  SecurityIcon,
  Stack,
  TNavBarProps,
  UserIcon,
  TAccessOperation,
  useAccessScopeValidator,
} from "@budgeinc/budge-ui-core";
import { Suspense, useCallback } from "react";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import { ROUTES } from "common/routes";
import useSession from "SessionContext";
import Breadcrumb from "components/Breadcrumb";
import { UserWhoamiOutputScopesEnum } from "@budgeinc/budge-api";
import CenteredSpinner from "components/CenteredSpinner";

const MainLayout = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { validateAccessScopes } = useAccessScopeValidator();
  const { logout, userAccessScopes, whoami } = useSession();

  const getNavBarItems = useCallback(() => {
    const items: TNavBarProps["items"] = [];

    if (validateAccessScopes(TAccessOperation.READ, [UserWhoamiOutputScopesEnum.Tenants])) {
      items.push({
        icon: UserIcon,
        title: "Tenants",
        onPress: () => navigate(ROUTES.employersList),
        active: !!location.pathname.includes(ROUTES.employersList),
      });
    }

    if (validateAccessScopes(TAccessOperation.READ)) {
      items.push({
        onPress: () => navigate(ROUTES.financialProfileList),
        icon: AddUserIcon,
        title: "Financial profiles",
        active: !!location.pathname.includes(ROUTES.financialProfileList),
      });
    }

    if (
      validateAccessScopes(TAccessOperation.READ, [
        {
          OR: [UserWhoamiOutputScopesEnum.Marketing, UserWhoamiOutputScopesEnum.MarketingRead],
        },
      ])
    ) {
      items.push({
        onPress: () => navigate(ROUTES.marketing),
        icon: InsightIcon,
        title: "Marketing",
        active: !!location.pathname.includes(ROUTES.marketing),
      });
    }

    if (validateAccessScopes(TAccessOperation.READ, [UserWhoamiOutputScopesEnum.AdvisoryNotices])) {
      items.push({
        onPress: () => navigate(ROUTES.advisoryNotices),
        icon: MessageIcon,
        title: "Advisory notices",
        active: !!location.pathname.includes(ROUTES.advisoryNotices),
      });
    }

    if (validateAccessScopes(TAccessOperation.READ, [UserWhoamiOutputScopesEnum.Security])) {
      items.push({
        onPress: () => navigate(ROUTES.security),
        icon: SecurityIcon,
        title: "Security",
        active: !!location.pathname.includes(ROUTES.security),
      });
    }

    if (validateAccessScopes(TAccessOperation.READ, [UserWhoamiOutputScopesEnum.MetricsKpis])) {
      items.push({
        icon: DocumentIcon,
        title: "Reporting",
        links: [
          {
            title: "Metrics & KPIs",
            onPress: () => navigate(ROUTES.metricsKpis),
            active: !!location.pathname.includes(ROUTES.metricsKpis),
          },
          {
            title: "Debt plan enrollments",
            onPress: () => navigate(ROUTES.debtPlanEnrollments),
            active: !!location.pathname.includes(ROUTES.debtPlanEnrollments),
          },
        ],
      });
    }

    if (validateAccessScopes(TAccessOperation.READ)) {
      items.push(
        {
          onPress: () => navigate(ROUTES.exports),
          icon: DownloadIcon,
          title: "Exports",
          active: !!location.pathname.includes(ROUTES.exports),
        },
        {
          onPress: () => navigate(ROUTES.advanced),
          icon: AdvancedSettingsIcon,
          title: "Advanced",
          active: !!location.pathname.includes(ROUTES.advanced),
        }
      );
    }

    return items;
  }, [location.pathname, location.hash, JSON.stringify(userAccessScopes)]);

  return (
    <Box f={1}>
      <Stack.Horizontal spacing={0} f={1}>
        <NavBar
          w={275}
          items={getNavBarItems()}
          footer={{
            userName: `${whoami?.user.firstName} ${whoami?.user.lastName}`,
            email: `${whoami?.user.email}`,
            onLogout: logout,
            onProfile: () => navigate(ROUTES.settings),
          }}
        />
        <Box
          f={1}
          px={24}
          pt={24}
          bg="dark.0"
          sx={{
            // @ts-ignore
            overflowY: "scroll",
            overflowX: "hidden",
          }}
        >
          <Suspense fallback={<CenteredSpinner pos="absolute" top={0} left={0} bottom={0} right={0} size="md" />}>
            <Breadcrumb mb="md" />
            <Outlet />
          </Suspense>
        </Box>
      </Stack.Horizontal>
    </Box>
  );
};

export default MainLayout;
